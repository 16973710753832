<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="客户">
        <base-select info="Client" v-model="filter.clientId"></base-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-refresh" @click="getData"
          >刷新</el-button
        >
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size: 12px"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>

    <pl-table
      :data="data"
      stripe
      border
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclick"
    >
      <el-table-column
        label="单据编号"
        prop="billCode"
        show-overflow-tooltip
        sortable
      ></el-table-column>
      <el-table-column label="单据日期" prop="billDate" width="100" sortable>
        <template slot-scope="scope">{{
          scope.row.billDate | datetime
        }}</template>
      </el-table-column>
      <el-table-column
        label="客户"
        prop="clientName"
        show-overflow-tooltip
        sortable
      ></el-table-column>
      <el-table-column
        label="收款金额"
        prop="amount"
        show-overflow-tooltip
        sortable
      >
        <template slot-scope="scope">{{
          scope.row.amount | thousands
        }}</template>
      </el-table-column>
      <el-table-column
        label="结算金额"
        prop="balanceAmount"
        show-overflow-tooltip
        sortable
      >
        <template slot-scope="scope">{{
          scope.row.balanceAmount | thousands
        }}</template>
      </el-table-column>
      <el-table-column
        label="摘要"
        prop="remark"
        show-overflow-tooltip
      ></el-table-column>
    </pl-table>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect.vue";
import setName from "@/common/setName";
import tableHeight from "@/common/tableHeightMixins";
import toExcel from "@/common/toExcel";
export default {
  mixins: [tableHeight],
  components: {
    BaseSelect,
  },
  data() {
    return {
      filter: {
        begin: this.$moment().format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD"),
      },
      data: [],
    };
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = { ...this.filter };
      this.$get("Receipt/GetList", params)
        .then((r) => {
          r.forEach((item) => {
            setName("Client", item, "clientId", "clientName");
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    dblclick(row) {
      if (this.hasPermission("Receipt")) {
        this.$router.push({
          name: "ReceiptItem",
          query: { id: row.id },
        });
      }
    },
    excel() {
      let list = [];
      this.data.forEach((item) => {
        let copy = { ...item };
        copy.billDate = this.$moment(copy.billDate).format("YYYY-MM-DD");
        list.push({
          ...copy,
        });
      });
      let header = [
        "单据编号",
        "单据日期",
        "客户",
        "收款金额",
        "结算金额",
        "摘要",
      ];
      let column = [
        "billCode",
        "billDate",
        "clientName",
        "amount",
        "balanceAmount",
        "remark",
      ];
      toExcel(header, column, list, "收款列表");
    },
  },
};
</script>

<style>
</style>